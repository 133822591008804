<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("LEAVE.TYPE") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <b-button
          class="btn btn-icon btn-light btn-hover-primary mr-3"
          v-on:click="refresh"
        >
          <span class="svg-icon svg-icon-2x svg-icon-primary">
            <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
          </span>
        </b-button>
        <b-button
          variant="primary"
          class="font-weight-bolder"
          v-b-toggle.department-list-sidebar
          >{{ $t("DEPARTMENT.BUTTON") }}</b-button
        >
        <b-sidebar
          ref="department-list-sidebar-ref"
          id="department-list-sidebar"
          backdrop
          right
          lazy
          :title="$t('LEAVE.LEAVEDETAILS')"
          sidebar-class="offcanvas"
          header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
          bg-variant="white"
        >
          <template v-slot:header-close>
            <button
              class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
            >
              <i class="ki ki-close icon-xs"></i>
            </button>
          </template>
          <div class="px-10 mt-5">
            <LeavePageDetailsForm
              v-slot:default="{}"
              :title="$t('LEAVE.LEAVETYPE')"
              v-on:close="testClose"
            ></LeavePageDetailsForm>
          </div>
        </b-sidebar>
      </div>
    </div>
    <div>
      <b-alert
        variant="info"
        show="5"
        fade
        dismissible
        v-if="formData.length > 0"
      >
        {{ formData }}
      </b-alert>
    </div>

    <LeavePageDetailsTable :reload="reload"></LeavePageDetailsTable>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LeavePageDetailsForm from "@/modules/company/components/leave/type/LeavePageDetailsForm";
import LeavePageDetailsTable from "@/modules/company/components/leave/type/LeavePageDetailsTable";

export default {
  name: "LeavePageDetails",
  components: { LeavePageDetailsTable, LeavePageDetailsForm },
  mounted() {
    console.log("Leave Type List");
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      {
        title: this.$t("SIDEBAR.DEPARTMENT"),
        route: "/company/department/list",
      },
    ]);
  },
  data() {
    return {
      formData: "",
      reload: false,
    };
  },
  methods: {
    testClose(data) {
      console.log(data);
      this.formData = "Successfully added department";
      console.log(this.formData);

      const sideBar = this.$refs["department-list-sidebar-ref"];
      sideBar.hide();

      this.refresh();
    },

    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
